import React, { useEffect, useState } from "react"
import { ReactSVG } from "react-svg";
import '../../../assets/css/soft-design-system.css';
import { defaultImages, illustrations, logos, people, shapes } from "../../../core/app_images";

import { fade, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import Slide from '@material-ui/core/Slide';
import { getCountOfProposals, getProposalByClient } from "../../../repositories/proposal_repository";
import { getCountOfClients } from "../../../repositories/client_repository";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        color: "white",
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '40ch',
            '&:focus': {
                width: '80ch',
            },
        },
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



const HomePage = () => {
    const classes = useStyles();
    const [numProposals, setNumProposals] = useState(0);
    const [numClients, setNumClients] = useState(0);
    const [openProposalsDialog, setOpenProposalsDialog] = useState(false);
    const [searchPropInput, setSearchPropInput] = useState("");
    const [listProposals, setListProposals] = useState([]);
    const handleClickOpen = () => {
        setOpenProposalsDialog(true);
    };

    const handleClose = () => {
        setOpenProposalsDialog(false);
    };

    const getListOfProposals = async (search) => {
        let response = await getProposalByClient(search);
        setListProposals(response.data)
    }

    const onSubmitSearchProposal = (ev) => {
        ev.preventDefault();
        getListOfProposals(searchPropInput)
    }

    useEffect(() => {
        const getCounts = async () => {
            let resProposals = await getCountOfProposals();
            setNumProposals(resProposals.count);

            let resClients = await getCountOfClients();
            setNumClients(resClients.count);
        }
        getCounts();
    }, [])
    return (
        <div className="about-us">
            <Helmet>
                <title>{"Propostas | Página Inicial"}</title>
                <meta name="description" content={"Solução para desenvolvimento de propostas comerciais online e sem burocracia!"} />
            </Helmet>
            <nav class="navbar navbar-expand-lg position-absolute top-0 z-index-3 w-100 shadow-none my-3  navbar-transparent ">
                <div class="container">
                    <a class="navbar-brand  text-white " href="/" rel="tooltip" title="Rafael GCS - Propostas" data-placement="bottom">
                        {"Rafael GCS - Propostas"}
                    </a>
                    <button class="navbar-toggler shadow-none ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon mt-2">
                            <span class="navbar-toggler-bar bar1"></span>
                            <span class="navbar-toggler-bar bar2"></span>
                            <span class="navbar-toggler-bar bar3"></span>
                        </span>
                    </button>
                    <div class="collapse navbar-collapse w-100 pt-3 pb-2 py-lg-0 ms-lg-12 ps-lg-5" id="navigation">
                        <ul class="navbar-nav navbar-nav-hover w-100">

                            <li class="nav-item ms-lg-auto">
                                <a class="nav-link nav-link-icon me-2" href="https://github.com/rafaelgcs" target="_blank">
                                    <i class="fa fa-github me-1"></i>
                                    <p class="d-inline text-sm z-index-1 font-bold" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Encontre-me no Github">Github</p>
                                </a>
                            </li>
                            <li class="nav-item my-auto ms-3 ms-lg-0">
                                <Link to="/admin" class="btn btn-sm  bg-white  btn-round mb-0 me-1 mt-2 mt-md-0">Gerenciador</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <header class="bg-dark">
                <div class="page-header section-height-75" style={{ backgroundImage: `url(${defaultImages.meeting1})` }}>
                    <span class="mask bg-dark opacity-8"></span>
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-8 text-center mx-auto my-auto">
                                <h1 class="text-white">Está procurando a sua proposta?</h1>
                                <p class="lead mb-4 text-white opacity-8">Se sim, clique no botão abaixo e faça a sua busca!</p>
                                <button onClick={handleClickOpen} class="btn bg-white text-dark">Encontrar proposta</button>
                            </div>
                        </div>
                    </div>
                    <div class="position-absolute w-100 z-index-1 bottom-0">
                        <ReactSVG src={shapes.waves.manyWhite} />
                    </div>
                </div>
            </header>
            <section class="py-7">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="row justify-content-left">
                                <div class="col-md-6">
                                    <div class="info">
                                        <div class="icon icon-shape text-center">
                                            <svg class="text-info" width="25px" height="25px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                <title>spaceship</title>
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <g transform="translate(-1720.000000, -592.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                                        <g transform="translate(1716.000000, 291.000000)">
                                                            <g transform="translate(4.000000, 301.000000)">
                                                                <path class="color-foreground" d="M39.3,0.706666667 C38.9660984,0.370464027 38.5048767,0.192278529 38.0316667,0.216666667 C14.6516667,1.43666667 6.015,22.2633333 5.93166667,22.4733333 C5.68236407,23.0926189 5.82664679,23.8009159 6.29833333,24.2733333 L15.7266667,33.7016667 C16.2013871,34.1756798 16.9140329,34.3188658 17.535,34.065 C17.7433333,33.98 38.4583333,25.2466667 39.7816667,1.97666667 C39.8087196,1.50414529 39.6335979,1.04240574 39.3,0.706666667 Z M25.69,19.0233333 C24.7367525,19.9768687 23.3029475,20.2622391 22.0572426,19.7463614 C20.8115377,19.2304837 19.9992882,18.0149658 19.9992882,16.6666667 C19.9992882,15.3183676 20.8115377,14.1028496 22.0572426,13.5869719 C23.3029475,13.0710943 24.7367525,13.3564646 25.69,14.31 C26.9912731,15.6116662 26.9912731,17.7216672 25.69,19.0233333 L25.69,19.0233333 Z"></path>
                                                                <path class="color-foreground" d="M1.855,31.4066667 C3.05106558,30.2024182 4.79973884,29.7296005 6.43969145,30.1670277 C8.07964407,30.6044549 9.36054508,31.8853559 9.7979723,33.5253085 C10.2353995,35.1652612 9.76258177,36.9139344 8.55833333,38.11 C6.70666667,39.9616667 0,40 0,40 C0,40 0,33.2566667 1.855,31.4066667 Z"></path>
                                                                <path class="color-background" d="M17.2616667,3.90166667 C12.4943643,3.07192755 7.62174065,4.61673894 4.20333333,8.04166667 C3.31200265,8.94126033 2.53706177,9.94913142 1.89666667,11.0416667 C1.5109569,11.6966059 1.61721591,12.5295394 2.155,13.0666667 L5.47,16.3833333 C8.55036617,11.4946947 12.5559074,7.25476565 17.2616667,3.90166667 L17.2616667,3.90166667 Z" opacity="0.598539807"></path>
                                                                <path class="color-background" d="M36.0983333,22.7383333 C36.9280725,27.5056357 35.3832611,32.3782594 31.9583333,35.7966667 C31.0587397,36.6879974 30.0508686,37.4629382 28.9583333,38.1033333 C28.3033941,38.4890431 27.4704606,38.3827841 26.9333333,37.845 L23.6166667,34.53 C28.5053053,31.4496338 32.7452344,27.4440926 36.0983333,22.7383333 L36.0983333,22.7383333 Z" id="color-3" opacity="0.598539807"></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        <h5>Mais rápido</h5>
                                        <p>Entrega de propostas rápido como um fogete (em tempo record).</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="info">
                                        <div class="icon icon-shape text-center">
                                            <svg class="text-info" width="43px" height="20px" viewBox="0 0 43 36" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                <title>credit-card</title>
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <g transform="translate(-2169.000000, -745.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                                        <g transform="translate(1716.000000, 291.000000)">
                                                            <g id="credit-card" transform="translate(453.000000, 454.000000)">
                                                                <path class="color-background" d="M43,10.7482083 L43,3.58333333 C43,1.60354167 41.3964583,0 39.4166667,0 L3.58333333,0 C1.60354167,0 0,1.60354167 0,3.58333333 L0,10.7482083 L43,10.7482083 Z" opacity="0.593633743">
                                                                </path>
                                                                <path class="color-foreground" d="M0,16.125 L0,32.25 C0,34.2297917 1.60354167,35.8333333 3.58333333,35.8333333 L39.4166667,35.8333333 C41.3964583,35.8333333 43,34.2297917 43,32.25 L43,16.125 L0,16.125 Z M19.7083333,26.875 L7.16666667,26.875 L7.16666667,23.2916667 L19.7083333,23.2916667 L19.7083333,26.875 Z M35.8333333,26.875 L28.6666667,26.875 L28.6666667,23.2916667 L35.8333333,23.2916667 L35.8333333,26.875 Z"></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        <h5>Grátis!</h5>
                                        <p>Não precisará gastar seu dinheiro para isso, basta criar a sua conta e cadastrar novas propostas!</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-left">
                                <div class="col-md-6">
                                    <div class="info">
                                        <div class="icon icon-shape text-center">
                                            <svg class="text-info" width="25px" height="25px" viewBox="0 0 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                <title>box-3d-50</title>
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <g transform="translate(-2319.000000, -291.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                                        <g transform="translate(1716.000000, 291.000000)">
                                                            <g id="box-3d-50" transform="translate(603.000000, 0.000000)">
                                                                <path class="color-foreground" d="M22.7597136,19.3090182 L38.8987031,11.2395234 C39.3926816,10.9925342 39.592906,10.3918611 39.3459167,9.89788265 C39.249157,9.70436312 39.0922432,9.5474453 38.8987261,9.45068056 L20.2741875,0.1378125 L20.2741875,0.1378125 C19.905375,-0.04725 19.469625,-0.04725 19.0995,0.1378125 L3.1011696,8.13815822 C2.60720568,8.38517662 2.40701679,8.98586148 2.6540352,9.4798254 C2.75080129,9.67332903 2.90771305,9.83023153 3.10122239,9.9269862 L21.8652864,19.3090182 C22.1468139,19.4497819 22.4781861,19.4497819 22.7597136,19.3090182 Z"></path>
                                                                <path class="color-background" d="M23.625,22.429159 L23.625,39.8805372 C23.625,40.4328219 24.0727153,40.8805372 24.625,40.8805372 C24.7802551,40.8805372 24.9333778,40.8443874 25.0722402,40.7749511 L41.2741875,32.673375 L41.2741875,32.673375 C41.719125,32.4515625 42,31.9974375 42,31.5 L42,14.241659 C42,13.6893742 41.5522847,13.241659 41,13.241659 C40.8447549,13.241659 40.6916418,13.2778041 40.5527864,13.3472318 L24.1777864,21.5347318 C23.8390024,21.7041238 23.625,22.0503869 23.625,22.429159 Z" opacity="0.7"></path>
                                                                <path class="color-background" d="M20.4472136,21.5347318 L1.4472136,12.0347318 C0.953235098,11.7877425 0.352562058,11.9879669 0.105572809,12.4819454 C0.0361450918,12.6208008 6.47121774e-16,12.7739139 0,12.929159 L0,30.1875 L0,30.1875 C0,30.6849375 0.280875,31.1390625 0.7258125,31.3621875 L19.5528096,40.7750766 C20.0467945,41.0220531 20.6474623,40.8218132 20.8944388,40.3278283 C20.963859,40.1889789 21,40.0358742 21,39.8806379 L21,22.429159 C21,22.0503869 20.7859976,21.7041238 20.4472136,21.5347318 Z" opacity="0.7"></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        <h5>Modelo pronto e seguro</h5>
                                        <p>Não é necessário dados como CPF, Cartão de crédito ou CNPJ</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="info">
                                        <div class="icon icon-shape text-center">
                                            <svg class="text-info" width="25px" height="25px" viewBox="0 0 46 42" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                <title>customer-support</title>
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <g transform="translate(-1717.000000, -291.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                                        <g transform="translate(1716.000000, 291.000000)">
                                                            <g transform="translate(1.000000, 0.000000)">
                                                                <path class="color-background" d="M45,0 L26,0 C25.447,0 25,0.447 25,1 L25,20 C25,20.379 25.214,20.725 25.553,20.895 C25.694,20.965 25.848,21 26,21 C26.212,21 26.424,20.933 26.6,20.8 L34.333,15 L45,15 C45.553,15 46,14.553 46,14 L46,1 C46,0.447 45.553,0 45,0 Z" opacity="0.59858631"></path>
                                                                <path class="color-foreground" d="M22.883,32.86 C20.761,32.012 17.324,31 13,31 C8.676,31 5.239,32.012 3.116,32.86 C1.224,33.619 0,35.438 0,37.494 L0,41 C0,41.553 0.447,42 1,42 L25,42 C25.553,42 26,41.553 26,41 L26,37.494 C26,35.438 24.776,33.619 22.883,32.86 Z"></path>
                                                                <path class="color-foreground" d="M13,28 C17.432,28 21,22.529 21,18 C21,13.589 17.411,10 13,10 C8.589,10 5,13.589 5,18 C5,22.529 8.568,28 13,28 Z"></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        <h5>Sem contato direto</h5>
                                        <p>Não precisará gastar tempo com e-mails, na própria plataforma pode enviar o e-mail para o cliente, informando sobre a proposta!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 ms-auto mt-lg-0 mt-4">
                            <div class="card shadow-lg">
                                <div class="card-header p-0 mx-3 mt-3 position-relative z-index-1">
                                    <div class="d-block blur-shadow-image">
                                        <img src={defaultImages.meeting2} alt="img-blur-shadow" class="img-fluid shadow rounded-3" />
                                    </div>
                                    <div class="colored-shadow" style={{ backgroundImage: `url(${defaultImages.meeting1})` }}></div>
                                </div>
                                <div class="card-body">
                                    <a href="javascript:;">
                                        <h5 class="mt-3">
                                            {"Menos burocracia e mais praticidade!"}
                                        </h5>
                                    </a>
                                    <p>
                                        {"A Proposta comercial acessível de qualquer lugar do mundo e em qualquer aparelho com acesso a internet, de forma prática e de fácil entendimento"}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="pt-2 pb-6 bg-gray-100" id="count-stats">
                <div class="container">
                    <div class="row mt-5 justify-content-center text-center">
                        <div class="col-md-3">
                            <h1 class="text-gradient text-info">{numProposals}+</h1>
                            <h5>Propostas</h5>
                            <p>Quantidade de propostas lançadas no sistema...</p>
                        </div>
                        <div class="col-md-3">
                            <h1 class="text-gradient text-info"><span id="state2" countTo="3400">{numClients}</span>+</h1>
                            <h5>Clientes</h5>
                            <p>Quantidade de clientes atendidos...</p>
                        </div>
                        <div class="col-md-3">
                            <h1 class="text-gradient text-info"><span id="state3" countTo="24">{numProposals}</span>/{numProposals}</h1>
                            <h5>Concluídos</h5>
                            <p>Projetos concluídos ou em andamento, já aprovado pelo cliente...</p>
                        </div>
                    </div>
                </div>
            </section>
            <footer class="footer">
                <div class="container">
                    <div class=" row">
                        <div class="col-12">
                            <div class="text-center">
                                <p class="my-4 text-sm">
                                    {"All rights reserved. Copyright © "}
                                    {new Date().getFullYear()}
                                    {". Rafael GCS - Propostas by"} <a href="https://www.rafaelgcs.com" target="_blank">Rafael Guimarães</a>{"."}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <Dialog fullScreen open={openProposalsDialog} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar position="static" color="primary" style={{ backgroundColor: "#343a40" }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography className={classes.title} variant="h6" noWrap>
                            {"Encontre a sua proposta"}
                        </Typography>
                        <form className={classes.search} onSubmit={onSubmitSearchProposal}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                placeholder="Digite aqui seu nome ou nome da empresa"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search', 'onChange': (ev) => setSearchPropInput(ev.target.value) }}
                            />
                        </form>
                    </Toolbar>
                </AppBar>
                <List>

                    {
                        listProposals.length == 0 &&
                        <ListItem button>
                            <ListItemText primary={"Nenhum item encontrado!"} secondary={"Favor efetuar uma nova busca"} />
                        </ListItem>
                    }
                    {
                        listProposals.map((proposal) => {
                            return (
                                <Link key={proposal.title} to={`/proposal/${proposal.client_id}-${proposal.id}`}>
                                    <ListItem button>
                                        <ListItemText primary={proposal.title} secondary={proposal.description} />
                                    </ListItem>
                                    <Divider />
                                </Link>
                            )
                        })
                    }
                </List>
            </Dialog>
        </div>
    );
}

export default HomePage;